import _ from "lodash";
import {addSingleDiskSync, searchDiskSyncsWithSearchCriteria} from "../services/disk-sync-data.service";

export {
    getCurrentDiskSyncByType,
}

const getCurrentDiskSyncByType = async (type, market?) => {
    let currentDiskSync;
    const searchCriteriaList = [];
    searchCriteriaList.push({
        field: 'status',
        condition: '==',
        value: 'NEW'
    });

    searchCriteriaList.push({
        field: 'type',
        condition: '==',
        value: type
    });

    if (market) {
        searchCriteriaList.push({
            field: 'market',
            condition: '==',
            value: market
        });
    }

    let shopifyProductPriceSyncResultList = await searchDiskSyncsWithSearchCriteria(searchCriteriaList);

    if (_.isEmpty(shopifyProductPriceSyncResultList)) {
        const newDiskSync: any = {
            status: 'NEW',
            type: type,
        }

        if (market) {
            newDiskSync.market = market;
        }

        const newDiskSyncResult: any = await addSingleDiskSync(newDiskSync);
        newDiskSync.id = newDiskSyncResult.id;
        currentDiskSync = newDiskSync;
    } else {
        currentDiskSync = shopifyProductPriceSyncResultList[0];
    }

    return currentDiskSync;
}
