import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    setDoc,
    where,
    orderBy,
    limit
} from "firebase/firestore";
import {fireStoreDB} from "../../firebase/client-app";
import {Promise} from "bluebird";
import _ from "lodash";
import {DATA_LIMIT} from "../constants/disk-detail-sync.constant";
import {prepareDocumentList, prepareSingleDocument} from "../../firebase/firebase-doc-util";

export {
    addDiskDetailSyncs,
    addSingleDiskDetailSync,
    updateSingleDiskDetailSync,
    getSingleDiskDetailSync,
    getAllDiskDetailSyncs,
    searchDiskDetailSyncs,
    searchDiskDetailSyncsWithSearchCriteria,
}

const collectionName = 'disk-detail-syncs';
const collectionReference = collection(fireStoreDB, collectionName);

const addDiskDetailSyncs = (diskDetailSyncs) => {
    return Promise.map(diskDetailSyncs, async diskDetailSync => {
        await addSingleDiskDetailSync(diskDetailSync);
    });
}

const addSingleDiskDetailSync = (diskDetailSync) => {
    diskDetailSync.createdAt = new Date();
    diskDetailSync.updatedAt = diskDetailSync.createdAt;
    return addDoc(collectionReference, diskDetailSync);
}

const updateSingleDiskDetailSync = (diskDetailSync) => {
    const documentReference = doc(fireStoreDB, collectionName, diskDetailSync.id);
    diskDetailSync.updatedAt = new Date();
    return setDoc(documentReference, diskDetailSync, {merge: true});
}

const getSingleDiskDetailSync = async (diskDetailSyncId) => {
    const documentReference = doc(fireStoreDB, collectionName, diskDetailSyncId);
    const diskDetailSyncDoc = await getDoc(documentReference);
    return prepareSingleDocument(diskDetailSyncDoc);
}

const getAllDiskDetailSyncs = async () => {
    let searchQuery = query(collectionReference);
    const diskDetailSyncsResult = await getDocs(searchQuery);
    return prepareDocumentList(diskDetailSyncsResult);
}

const searchDiskDetailSyncs = async (searchText, perPage = DATA_LIMIT) => {
    let searchQuery = query(collectionReference);

    if (searchText) {
        searchQuery = query(collectionReference, where("textList", "array-contains", searchText));
    }

    searchQuery = query(searchQuery,
        orderBy('updatedAt', 'desc'));

    searchQuery = query(searchQuery, limit(perPage));

    const diskDetailSyncResult = await getDocs(searchQuery);
    return prepareDocumentList(diskDetailSyncResult);
}

const searchDiskDetailSyncsWithSearchCriteria = async (searchCriteriaList, perPage = DATA_LIMIT) => {
    let searchQuery = query(collectionReference);
    searchQuery = query(searchQuery, orderBy('updatedAt', 'desc'));

    _.forEach(searchCriteriaList, searchCriteria => {
        searchQuery = query(searchQuery,
            where(searchCriteria.field, searchCriteria.condition, searchCriteria.value));
    });

    searchQuery = query(searchQuery, limit(perPage));

    const diskDetailSyncResult = await getDocs(searchQuery);
    return prepareDocumentList(diskDetailSyncResult);
}

