import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    setDoc,
    where,
    orderBy,
    limit
} from "firebase/firestore";
import {Promise} from "bluebird";
import _ from "lodash";
import dateFormat from "dateformat";
import {fireStoreDB} from "../../firebase/client-app";
import {DATA_LIMIT} from "../constants/disk-sync.constant";

export {
    addDiskSyncs,
    addSingleDiskSync,
    updateSingleDiskSync,
    getSingleDiskSync,
    getAllDiskSyncs,
    searchDiskSyncs,
    searchDiskSyncsWithSearchCriteria,
    prepareDiskSyncsList
}

const collectionName = 'disk-syncs';
const collectionReference = collection(fireStoreDB, collectionName);

const addDiskSyncs = (diskSyncs) => {
    return Promise.map(diskSyncs, async diskSync => {
        await addSingleDiskSync(diskSync);
    });
}

const addSingleDiskSync = (diskSync) => {
    diskSync.createdAt = new Date();
    diskSync.updatedAt = diskSync.createdAt;
    return addDoc(collectionReference, diskSync);
}

const updateSingleDiskSync = (diskSync) => {
    const documentReference = doc(fireStoreDB, collectionName, diskSync.id);
    diskSync.updatedAt = new Date();
    return setDoc(documentReference, diskSync);
}

const getSingleDiskSync = async (diskSyncId) => {
    const documentReference = doc(fireStoreDB, collectionName, diskSyncId);
    const diskSyncDoc = await getDoc(documentReference);
    return prepareSingleDiskSync(diskSyncDoc);
}

const getAllDiskSyncs = async () => {
    let searchQuery = query(collectionReference);
    const diskSyncsResult = await getDocs(searchQuery);
    return prepareDiskSyncsList(diskSyncsResult);
}

const searchDiskSyncs = async (searchText, perPage = DATA_LIMIT) => {
    let searchQuery = query(collectionReference);

    if (searchText) {
        searchQuery = query(collectionReference, where("textList", "array-contains", searchText));
    }

    searchQuery = query(searchQuery,
        orderBy('updatedAt', 'desc'));

    searchQuery = query(searchQuery, limit(perPage));

    const diskSyncResult = await getDocs(searchQuery);
    return prepareDiskSyncsList(diskSyncResult);
}

const searchDiskSyncsWithSearchCriteria = async (searchCriteriaList, perPage = DATA_LIMIT) => {
    let searchQuery = query(collectionReference);

    // console.log('searchDiskSyncsWithSearchCriteria', {searchCriteriaList});

    _.forEach(searchCriteriaList, searchCriteria => {
        searchQuery = query(searchQuery,
            where(searchCriteria.field, searchCriteria.condition, searchCriteria.value));
    });

    if (searchCriteriaList.length === 0) {
        searchQuery = query(searchQuery, orderBy('updatedAt', 'desc'));
    }

    searchQuery = query(searchQuery, limit(perPage));

    const diskSyncResult = await getDocs(searchQuery);
    return prepareDiskSyncsList(diskSyncResult);
}

const prepareDiskSyncsList = (diskSyncResult) => {
    const diskSyncsList = [];

    _.forEach(diskSyncResult.docs, diskSyncDoc => {
        diskSyncsList.push(prepareSingleDiskSync(diskSyncDoc));
    });

    return diskSyncsList;
}


const prepareSingleDiskSync = (diskSyncDoc) => {
    const diskSync = diskSyncDoc.data();
    diskSync.id = diskSyncDoc.id;
    
    diskSync.updatedAtText = convertDateToText(diskSync.updatedAt);
    diskSync.createdAtText = convertDateToText(diskSync.createdAt);
    return diskSync;
}

const convertDateToText = (date) => {
    return dateFormat(new Date(date.seconds * 1000));
}
