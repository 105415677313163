import React from 'react';
import LoginDetail from "./login-detail";


const ShowNoAccessMessage = () => {
    return (

        <>
            <div className="flex items-center justify-center w-full mt-28">
                <div
                    className="rounded flex items-center justify-center border h-36 w-1/2 bg-red-100 border-red-800 text-red-800">
                    <div className="m-4 text-xl">
                        Sorry, you don't have access to this application.
                    </div>
                </div>
            </div>

            <LoginDetail/>
        </>

    )
}

export default ShowNoAccessMessage
