import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    setDoc,
    where,
    orderBy,
    limit
} from "firebase/firestore";
import {fireStoreDB} from "../../firebase/client-app";
import {Promise} from "bluebird";
import _ from "lodash";
import dateFormat from "dateformat";
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en'
import {DATA_LIMIT} from "../constants/disk.constant";

export {
    addDiskMarkets,
    addSingleDiskMarket,
    updateSingleDiskMarket,
    getSingleDiskMarket,
    getAllDiskMarkets,
    searchDiskMarkets,
    searchDiskMarketsWithSearchCriteria,
    prepareDiskMarketsList
}

const collectionName = 'disk-markets';
const collectionReference = collection(fireStoreDB, collectionName);

TimeAgo.addDefaultLocale(en)
const timeAgo = new TimeAgo('en-US');

const addDiskMarkets = (diskMarkets) => {
    return Promise.map(diskMarkets, async diskMarket => {
        await addSingleDiskMarket(diskMarket);
    });
}

const addSingleDiskMarket = (diskMarket) => {
    diskMarket.createdAt = new Date();
    diskMarket.updatedAt = diskMarket.createdAt;
    return addDoc(collectionReference, diskMarket);
}

const updateSingleDiskMarket = (diskMarket) => {
    const documentReference = doc(fireStoreDB, collectionName, diskMarket.id);
    diskMarket.updatedAt = new Date();
    return setDoc(documentReference, diskMarket);
}

const getSingleDiskMarket = async (diskMarketId) => {
    const documentReference = doc(fireStoreDB, collectionName, diskMarketId);
    const diskMarketDoc = await getDoc(documentReference);
    return prepareSingleDiskMarket(diskMarketDoc);
}

const getAllDiskMarkets = async () => {
    let searchQuery = query(collectionReference);
    const diskMarketsResult = await getDocs(searchQuery);
    return prepareDiskMarketsList(diskMarketsResult);
}

const searchDiskMarkets = async (searchText, perPage = DATA_LIMIT) => {
    let searchQuery = query(collectionReference);

    if (searchText) {
        searchQuery = query(collectionReference, where("textList", "array-contains", searchText));
    }

    searchQuery = query(searchQuery,
        orderBy('updatedAt', 'desc'));

    searchQuery = query(searchQuery, limit(perPage));

    const diskMarketResult = await getDocs(searchQuery);
    return prepareDiskMarketsList(diskMarketResult);
}

const searchDiskMarketsWithSearchCriteria = async (searchCriteriaList, perPage = DATA_LIMIT) => {
    let searchQuery = query(collectionReference);

    // console.log('searchDiskMarketsWithSearchCriteria', {searchCriteriaList});

    _.forEach(searchCriteriaList, searchCriteria => {
        searchQuery = query(searchQuery,
            where(searchCriteria.field, searchCriteria.condition, searchCriteria.value));
    });

    /*if (searchCriteriaList.length === 0) {
        searchQuery = query(searchQuery, orderBy('updatedAt', 'desc'));
    }*/

    searchQuery = query(searchQuery, limit(perPage));

    const diskMarketResult = await getDocs(searchQuery);
    return prepareDiskMarketsList(diskMarketResult);
}

const prepareDiskMarketsList = (diskMarketResult) => {
    const diskMarketsList = [];

    _.forEach(diskMarketResult.docs, diskMarketDoc => {
        diskMarketsList.push(prepareSingleDiskMarket(diskMarketDoc));
    });

    return diskMarketsList;
}

const prepareSingleDiskMarket = (diskMarketDoc) => {
    const diskMarket = diskMarketDoc.data();
    diskMarket.id = diskMarketDoc.id;
    const jsDate = diskMarket.updatedAt.toDate();
    diskMarket.lastRefreshAt = dateFormat(jsDate);
    diskMarket.timeAgo = timeAgo.format(jsDate);

    return diskMarket;
}
