import React from "react"

const ShowProgressSpinner = ({dataFetchInProgress, sizeClass = 'w-10 h-10'}) => {
    return (
        <>
            {dataFetchInProgress &&
                <div className="flex justify-center">
                    <div className={sizeClass}>
                        <img
                            className="animate-spin"
                            src={'../../assets/images/loader.png'}
                            alt={'Loading'}
                        />
                    </div>
                </div>
            }
        </>
    )
}

export default ShowProgressSpinner
