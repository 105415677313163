import React, {useEffect, useState} from 'react';
import _ from "lodash";
import {DISK_MARKET_FIELDS_LIST} from "../constants/disk-market.constant";
import ShowProgressSpinner from '../../common/ui/show-progress-spinner';
import ShowEmptyListMessage from '../../common/ui/show-empty-list-message';
import {getAllDiskMarkets} from "../../disk/services/disk-market-data.service";
import {AMAZON_MARKETS_MAP} from "../../disk/constants/disk.constant";
import {syncDiskPrices} from "../../common/services/cloud-function-service";
import CopyDataToClipboard from '../../common/ui/copy-data-to-clipboard';
import {getCurrentDiskSyncByType} from "../../disk-sync/utils/disk-sync-util";
import {updateSingleDiskSync} from "../../disk-sync/services/disk-sync-data.service";
import {syncAllMarketDiskDetails, syncDiskDetails} from "../services/disk-market-service";

const defaultFilterCriteria: any = {};

const DiskMarketsList = () => {
    const [diskMarketsList, setDiskMarketsList] = useState(null);
    const [filterCriteria, setFilterCriteria] = useState(defaultFilterCriteria);
    const [dataFetchInProgress, setDataFetchInProgress] = useState(true);

    const getDiskMarketList = async () => {
        setDataFetchInProgress(true);

        const diskMarketListResult = await getAllDiskMarkets();

        const enabledMarkets = [];
        diskMarketListResult?.forEach((diskMarket: any) => {
            const diskMarketInfo: any = AMAZON_MARKETS_MAP[diskMarket?.market];
            diskMarket['label'] = diskMarketInfo?.label;
            diskMarket['url'] = diskMarketInfo?.url;
            diskMarket['href'] = diskMarketInfo?.href;
            diskMarket['tag'] = diskMarketInfo?.tag;

            if (diskMarketInfo && !diskMarketInfo?.disabled) {
                enabledMarkets.push(diskMarket);
            }
        });

        setDiskMarketsList(_.orderBy(enabledMarkets, ['updatedAt.seconds'], ['desc']));
        setDataFetchInProgress(false);
    }

    useEffect(() => {
        getDiskMarketList();
    }, [filterCriteria]);

    const refreshDiskMarketList = async () => {
        await getDiskMarketList();
    }

    const startDiskDetailsSyncProcess = async (diskMarket) => {
        setDataFetchInProgress(true);
        await syncDiskDetails(diskMarket.market);

        await refreshDiskMarketList();
        setDataFetchInProgress(false);
    }

    const startAllDiskDetailsSyncProcess = async () => {
        setDataFetchInProgress(true);
        await syncAllMarketDiskDetails();

        await refreshDiskMarketList();
        setDataFetchInProgress(false);
    }

    const startDiskPricesSyncProcess = async () => {
        setDataFetchInProgress(true);
        await syncDiskPrices();

        await refreshDiskMarketList();
        setDataFetchInProgress(false);
    }

    const addToDesignSync = async (diskMarket) => {
        setDataFetchInProgress(true);

        const currentDesignSync = await getCurrentDiskSyncByType('DISK-DETAIL');

        const diskIds = [];
        _.forEach(diskMarket.diskIds, (diskId) => {
            diskIds.push(_.split(diskId, '|')[0]);
        });

        currentDesignSync.diskIds = _.uniq(diskIds);
        currentDesignSync.market = diskMarket.market;
        currentDesignSync.total = currentDesignSync.diskIds.length;
        await updateSingleDiskSync(currentDesignSync);

        console.log('currentDesignSync', {diskMarket, currentDesignSync});

        setDataFetchInProgress(false);
    }

    return (
        <>

            <div className="flex justify-between">

                <div className="flex justify-start items-center -ml-2 mb-2 w-full">
                    <div className={'text-xl ml-2'}>
                        Disk Markets
                    </div>

                    <div className="ml-4">
                        <ShowProgressSpinner dataFetchInProgress={dataFetchInProgress}/>
                    </div>
                </div>

                <div className="text-center w-1/6 flex items-center justify-end">
                    {/*<div className={'-mt-2'}>
                        <button
                            className="button-green px-4 py-2 flex items-center justify-center"
                            onClick={startDiskPricesSyncProcess}>
                            <span>Sync Disk Prices</span>
                        </button>
                    </div>*/}
                    <div className={'-mt-2'}>
                        <button
                            className="button-green px-4 py-2 flex items-center justify-center"
                            onClick={startAllDiskDetailsSyncProcess}>
                            <span>Sync All Disk Prices</span>
                        </button>
                    </div>
                </div>

            </div>

            <div className="flex justify-start">

                <div
                    className="w-full bg-white mt-4">

                    <ShowEmptyListMessage
                        dataList={diskMarketsList}
                        dataFetchInProgress={dataFetchInProgress}
                        label={'Disk Markets'}/>

                    {diskMarketsList?.length > 0 &&
                        <table className="w-full">
                            <thead className="">
                            <tr>
                                <th className="p-2 border border-gray-400 bg-gray-400 text-white w-12">S.No</th>
                                {DISK_MARKET_FIELDS_LIST?.map((diskMarketField, rowIndex) => (
                                    <th key={`th-${rowIndex}`}
                                        className={`p-2 border border-gray-400 bg-gray-400 text-white text-left pl-2 ${diskMarketField?.cssClass}`}>{diskMarketField?.label}</th>
                                ))}
                                <th className="p-2 border border-gray-400 bg-gray-400 text-white">
                                    Actions
                                </th>
                            </tr>
                            </thead>
                            <tbody className="">
                            {diskMarketsList?.map((diskMarket, rowIndex) => (
                                <tr key={`row-${rowIndex}`} className="border border-gray-400">
                                    <td className="p-2 border border-gray-400 m-auto">
                                        <div className="flex justify-center w-12">
                                            {rowIndex + 1}
                                        </div>
                                    </td>

                                    {DISK_MARKET_FIELDS_LIST?.map((diskMarketField, columnIndex) => (
                                        <React.Fragment key={`column-${rowIndex}-${columnIndex}`}>
                                            <td className={`p-2 border border-gray-400 m-auto ${diskMarketField?.cssClass}`}>
                                                {diskMarketField?.optionsMap &&
                                                    <>{diskMarketField?.optionsMap[diskMarket[diskMarketField?.attribute]]?.name}</>
                                                }

                                                {!diskMarketField?.optionsMap &&
                                                    <>
                                                        {diskMarket[diskMarketField?.attribute]}

                                                        {diskMarketField?.secondaryAttribute &&
                                                            <div className={'text-gray-500 text-sm'}>
                                                                {diskMarket[diskMarketField?.secondaryAttribute]}
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </td>
                                        </React.Fragment>
                                    ))}

                                    <td className="p-2 border border-gray-400 m-auto w-fit">
                                        <div className="flex justify-center items-center">
                                            <CopyDataToClipboard value={JSON.stringify(diskMarket, null, 2)}/>

                                            {/*<div className={'mx-1'}>
                                                <button
                                                    className="button-green-outline px-3 py-1 flex items-center justify-center"
                                                    onClick={() => {
                                                        addToDesignSync(diskMarket)
                                                    }}>
                                                    <span>Add To Disk Details Sync</span>
                                                </button>
                                            </div>*/}

                                            <div className={'mx-1'}>
                                                <button
                                                    className="button-green-outline px-3 py-1 flex items-center justify-center"
                                                    onClick={() => {
                                                        startDiskDetailsSyncProcess(diskMarket)
                                                    }}>
                                                    <span>Start Disk Details Sync</span>
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    }
                </div>

            </div>

        </>
    )
}

export default DiskMarketsList
