import _ from "lodash";
import dateFormat from "dateformat";

export {
    prepareDocumentList,
    prepareSingleDocument,
    generateUniqueId,
};


const prepareDocumentList = (documentsResult) => {
    const documentsList = [];

    _.forEach(documentsResult.docs, (document) => {
        const documentDetail = prepareSingleDocument(document);

        if (!_.isEmpty(documentDetail)) {
            documentsList.push(documentDetail);
        }
    });

    return documentsList;
};

const prepareSingleDocument = (documentResult) => {
    let documentDetail: any = {};

    if (!documentResult.exists) {
        return documentDetail;
    }

    documentDetail = documentResult.data();
    documentDetail.id = documentResult.id;

    documentDetail.updatedAtText = convertDateToText(documentDetail?.updatedAt);
    documentDetail.createdAtText = convertDateToText(documentDetail?.createdAt);

    return documentDetail;
};


const generateUniqueId = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const idLength = 20;

    let uniqueId = '';
    for (let i = 0; i < idLength; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        uniqueId += characters.charAt(randomIndex);
    }

    return uniqueId;
}

const convertDateToText = (dateObject) => {
    if (dateObject) {
        return dateFormat(new Date(dateObject?.seconds * 1000));
    }

    return '';
}
