import React, {useContext} from 'react';
import {ArrowRightEndOnRectangleIcon, UserCircleIcon} from "@heroicons/react/24/solid";
import {appConfig, logout} from "../firebase/client-app";
import AppContext from "../common/context/app-context";
import {AMAZON_MARKETS} from "../disk/constants/disk.constant";

const menus: any = AMAZON_MARKETS;

const Header = () => {
    const appContext = useContext(AppContext);
    let {profile, isAuthenticated} = appContext;

    return (
        <>
            <div className="relative sticky top-0 left-0 w-full mx-auto">
                <div className="max-w-full mx-auto">
                    <div className="relative z-10 bg-slate-500">

                        <div className="relative">
                            <div className="relative flex items-center justify-between h-fit px-2 py-3 text-white"
                                 aria-label="Global">
                                <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                                    <a href={'/'} className="flex items-center justify-between w-full ml-4">
                                        {appConfig?.appName}
                                    </a>
                                </div>

                                {isAuthenticated &&
                                    <div className="flex flex-wrap items-center ml-4">
                                        {menus.map((item, index) => (
                                            <div key={index}>
                                                <a href={item.href}
                                                   className="font-medium text-white hover:text-gray-300 pr-2">
                                                    {item.label}
                                                </a>
                                                {index < menus.length - 1 &&
                                                    <span className="mr-2">|</span>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                }

                                {isAuthenticated &&
                                    <div className="flex mr-4 items-end">
                                        <a href={'/markets'}
                                           className="font-medium text-white hover:text-gray-300 pr-2">
                                            Markets
                                        </a>
                                        <span className="mr-2">|</span>
                                        <a href={'/disk-syncs'}
                                           className="font-medium text-white hover:text-gray-300 pr-2">
                                            Disk Syncs
                                        </a>
                                        <span className="mr-2">|</span>
                                        <a href={'/disk-detail-syncs'}
                                           className="font-medium text-white hover:text-gray-300 pr-2">
                                            Disk Detail Syncs
                                        </a>
                                    </div>
                                }

                                {isAuthenticated &&
                                    <div className="flex mr-4 items-center">
                                        <span className="ml-2 mr-2 text-white">
                                            <UserCircleIcon className="h-6 w-6"/>
                                        </span>
                                        <span className="font-light pr-4">
                                            {profile?.displayName}
                                        </span>
                                        <button onClick={logout}
                                                className="font-light hover:text-gray-200 pr-2 cursor-pointer">
                                            <ArrowRightEndOnRectangleIcon className="h-6 w-6"/>
                                        </button>
                                    </div>
                                }

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Header
