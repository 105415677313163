export const DISK_MARKET_FIELDS_LIST: any = [
    {attribute: "label", label: "Market", cssClass: ''},
    {attribute: "market", label: "Market Key", cssClass: 'text-center', secondaryAttribute: 'href'},
    // {attribute: "href", label: "Link", cssClass: ''},
    {attribute: "url", label: "Amazon Base Url", cssClass: ''},
    {attribute: "tag", label: "Amazon Tag", cssClass: ''},
    {attribute: "enabled", label: "Enabled", cssClass: 'text-center'},
    {attribute: "disabled", label: "Disabled", cssClass: 'text-center'},
    // {attribute: "total", label: "Total", cssClass: 'text-center'},
    {attribute: "timeAgo", label: "Last Refreshed", cssClass: 'text-center', secondaryAttribute: 'lastRefreshAt'},
    // {attribute: "lastRefreshAt", label: "Last Refreshed At", cssClass: '', secondaryAttribute: 'timeAgo'},
];

export const DISK_MARKET_INPUT_FIELDS: any = [
    {attribute: "market", label: "Market", type: 'text', group: 'detail', cssClass: ''},
    {attribute: "total", label: "Total", type: 'text', group: 'detail', cssClass: ''},
    {attribute: "enabled", label: "Enabled", type: 'text', group: 'detail', cssClass: ''},
    {attribute: "disabled", label: "Disabled", type: 'text', group: 'detail', cssClass: ''},
    {attribute: "updatedAt", label: "Updated At", type: 'text', group: 'detail', cssClass: ''},
];
