import React from 'react';
import DiskMarketList from "./disk-market-list";

const DiskMarketDashboard = () => {
    return (
        <div className="page-container">

            <div className="items-center justify-start p-6 m-auto bg-white rounded-lg my-8 w-full mx-6">

                <DiskMarketList/>

            </div>

        </div>
    );
};

export default DiskMarketDashboard;
