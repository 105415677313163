import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    setDoc,
    where,
    orderBy,
    limit
} from "firebase/firestore";
import {fireStoreDB} from "../../firebase/client-app";
import {Promise} from "bluebird";
import _ from "lodash";
import {DATA_LIMIT} from "../constants/disk.constant";

export {
    addDisks,
    addSingleDisk,
    updateSingleDisk,
    getSingleDisk,
    getAllDisks,
    searchDisks,
    searchDisksWithSearchCriteria,
    prepareDisksList
}

const collectionName = 'disks';
const collectionReference = collection(fireStoreDB, collectionName);

const addDisks = (disks) => {
    return Promise.map(disks, async disk => {
        await addSingleDisk(disk);
    });
}

const addSingleDisk = (disk) => {
    disk.createdAt = new Date();
    disk.updatedAt = disk.createdAt;
    return addDoc(collectionReference, disk);
}

const updateSingleDisk = (disk) => {
    const documentReference = doc(fireStoreDB, collectionName, disk.id);
    disk.updatedAt = new Date();
    return setDoc(documentReference, disk);
}

const getSingleDisk = async (diskId) => {
    const documentReference = doc(fireStoreDB, collectionName, diskId);
    const diskDoc = await getDoc(documentReference);
    return prepareSingleDisk(diskDoc);
}

const getAllDisks = async () => {
    let searchQuery = query(collectionReference);
    const disksResult = await getDocs(searchQuery);
    return prepareDisksList(disksResult);
}

const searchDisks = async (searchText, perPage = DATA_LIMIT) => {
    let searchQuery = query(collectionReference);

    if (searchText) {
        searchQuery = query(collectionReference, where("textList", "array-contains", searchText));
    }

    searchQuery = query(searchQuery,
        orderBy('updatedAt', 'desc'));

    searchQuery = query(searchQuery, limit(perPage));

    const diskResult = await getDocs(searchQuery);
    return prepareDisksList(diskResult);
}

const searchDisksWithSearchCriteria = async (searchCriteriaList, perPage = DATA_LIMIT) => {
    let searchQuery = query(collectionReference);
    searchQuery = query(searchQuery, orderBy('order', 'asc'));

    _.forEach(searchCriteriaList, searchCriteria => {
        searchQuery = query(searchQuery,
            where(searchCriteria.field, searchCriteria.condition, searchCriteria.value));
    });

    /*if (searchCriteriaList.length === 0) {
        searchQuery = query(searchQuery, orderBy('updatedAt', 'desc'));
    }*/

    /*if (perPage !== -1) {
        searchQuery = query(searchQuery, limit(perPage));
    }*/

    if (searchCriteriaList.length <= 2) {
        searchQuery = query(searchQuery, limit(perPage));
    }

    const diskResult = await getDocs(searchQuery);
    return prepareDisksList(diskResult);
}

const prepareDisksList = (diskResult) => {
    const disksList = [];

    _.forEach(diskResult.docs, diskDoc => {
        disksList.push(prepareSingleDisk(diskDoc));
    });

    return disksList;
}


const prepareSingleDisk = (diskDoc) => {
    const disk = diskDoc.data();
    disk.id = diskDoc.id;
    return disk;
}
