import {initializeApp} from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import {
    getAuth,
    GoogleAuthProvider,
    signInWithPopup,
    signOut,
} from "firebase/auth";
import {collection, doc, getDocs, getFirestore, query, setDoc, where} from "firebase/firestore";
import {getStorage, ref, deleteObject} from "firebase/storage";
import _ from "lodash";
import {DEFAULT_ADMIN_USERS} from "./firebase.constant";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const fireStoreDB = getFirestore(app);
const firebaseStorage = getStorage(app);
const storageRef = ref;

const USERS_COLLECTION = "users";


const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
    try {
        const res = await signInWithPopup(auth, googleProvider);
        const user: any = res.user;

        let userInfo: any = {
            id: user.uid,
            uid: user.uid,
            isEnabled: false,
            isAdmin: false,
            displayName: user.displayName,
            authProvider: "google",
            email: user.email,
            emailVerified: user.reloadUserInfo.emailVerified,
            photoUrl: user.reloadUserInfo.photoUrl,
            lastLoginAt: user.reloadUserInfo.lastLoginAt,
            lastRefreshAt: user.reloadUserInfo.lastRefreshAt,
            createdAt: user.reloadUserInfo.createdAt,
        }

        const userQuery = query(collection(fireStoreDB, USERS_COLLECTION), where("email", "==", user.email));
        const userDocs = await getDocs(userQuery);

        if (userDocs.docs.length > 0) {
            const existingUserInfo = userDocs.docs[0].data();
            userInfo.isEnabled = existingUserInfo.isEnabled || false;
            userInfo.isAdmin = existingUserInfo.isAdmin || false;
        }

        const defaultAdminUsersByEmailMap = _.keyBy(DEFAULT_ADMIN_USERS, "email");
        const defaultAdminUser = defaultAdminUsersByEmailMap[user.email];

        if (defaultAdminUser) {
            userInfo.isEnabled = defaultAdminUser.isEnabled;
            userInfo.isAdmin = defaultAdminUser.isAdmin;
        }

        const userDocumentReference = doc(fireStoreDB, USERS_COLLECTION, user.uid);
        await setDoc(userDocumentReference, userInfo, {merge: true});
    } catch (err) {
        console.error(err);
    }
};

const logout = () => {
    signOut(auth);
};

const getCurrentUserIdToken = async () => {
    const user = auth.currentUser;
    const idToken = await user?.getIdToken();
    return idToken;
}

const appConfig = {
    appName: process.env.REACT_APP_NAME,
    appKey: process.env.REACT_APP_PROJECT_ID,
}

const cloudFunctionConfig = {
    baseUrl: process.env.REACT_APP_CLOUD_FUNCTION_BASE_URL,
    localBaseUrl: process.env.REACT_APP_CLOUD_FUNCTION_LOCAL_BASE_URL,
    syncDiskPricesUrl: process.env.REACT_APP_SYNC_DISK_PRICES_URL,
    diskApiUrl: process.env.REACT_APP_DISK_API_URL,
}

const diskNoviceServiceConfig = {
    baseUrl: process.env.REACT_APP_DISK_NOVICE_SERVICE_URL,
    marketScrapeUrl: process.env.REACT_APP_DISK_MARKET_SCRAPE_URL,
    allMarketScrapeUrl: process.env.REACT_APP_ALL_DISK_MARKET_SCRAPE_URL,
}

export {
    cloudFunctionConfig,
    diskNoviceServiceConfig,
    appConfig,
    auth,
    fireStoreDB,
    firebaseStorage,
    storageRef,
    deleteObject,
    signInWithGoogle,
    logout,
    getCurrentUserIdToken,
};
