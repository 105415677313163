import React from 'react';
import DiskDetailSyncsListTable from "./disk-detail-sync-list-table";

const DiskDetailSyncDashboard = () => {
    return (
        <>

            <div className="page-container">

                <div className="items-center justify-start p-6 m-auto bg-white rounded-lg my-6 w-full mx-6">

                    <DiskDetailSyncsListTable/>

                </div>

            </div>

        </>
    )
}

export default DiskDetailSyncDashboard
