import _ from "lodash";

export {
    prepareDiskInfo,
}

const prepareDiskInfo = async (diskDetail) => {
    const technical = _.map(diskDetail.technical, (value, key) => {
        return value.title + ': ' + value.value;
    });

    const overview = _.map(diskDetail.overview, (value, key) => {
        console.log('value', value);
        return value.title + ': ' + value.value;
    });

    const diskInfo = {
        title: diskDetail.title,
        model: diskDetail.model,
        series: diskDetail.series,
        color: diskDetail.color,
        compatibleWith: diskDetail.compatibleWith,
        overview: overview,
        features: diskDetail.features,
        technical: technical,
    };

    return diskInfo;
}
