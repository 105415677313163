export const DATA_LIMIT = 30;

export const DISK_DETAIL_SYNC_FIELDS_LIST: any = [
    {attribute: "market", label: "Market", cssClass: ''},
    {attribute: "completed", label: "Completed", cssClass: ''},
    {attribute: "total", label: "Total", cssClass: ''},
    {attribute: "status", label: "Status", cssClass: ''},
    {attribute: "updatedAtText", label: "Updated At", cssClass: ''},
    {attribute: "failed", label: "Failed", cssClass: ''},
    {attribute: "timeline", label: "Timeline", cssClass: ''},
];


export const DISK_DETAIL_SYNC_INPUT_FIELDS: any = [
    {attribute: "market", label: "Market", type: 'text', group: 'detail', cssClass: ''},
    {attribute: "completed", label: "Completed", type: 'text', group: 'detail', cssClass: ''},
    {attribute: "total", label: "Total", type: 'text', group: 'detail', cssClass: ''},
    {attribute: "status", label: "Status", type: 'text', group: 'detail', cssClass: ''},
    {attribute: "updatedAt", label: "Updated At", type: 'text', group: 'detail', cssClass: ''},
    {attribute: "failed", label: "Failed", type: 'text', group: 'detail', cssClass: ''},
    {attribute: "timeline", label: "Timeline", type: 'text', group: 'detail', cssClass: ''},
];
