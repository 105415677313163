import _ from "lodash";

export const DATA_LIMIT = 10;

export const DISK_MARKET_SUFFIX = "amazon";
export const DEFAULT_MARKET_KEY = "US";

export const DISK_FIELDS_LIST: any = [
    {attribute: "market", label: "Market", cssClass: ''},
    {attribute: "asin", label: "ASIN", cssClass: ''},
    {attribute: "thumbnail", label: "Image", cssClass: 'text-center', isImage: true, altText: 'name'},
    {attribute: "name", label: "Name", cssClass: ''},
    {attribute: "formFactor", label: "Form Factor", cssClass: ''},
    {attribute: "technology", label: "Technology", cssClass: ''},
    {attribute: "condition", label: "Condition", cssClass: ''},
    // {attribute: "url", label: "URL", cssClass: ''},
    // {attribute: "pricePerGb", label: "Price Per GB", cssClass: ''},
    // {attribute: "pricePerTb", label: "Price Per TB", cssClass: ''},
    {attribute: "price", label: "Price", cssClass: ''},
    {attribute: "capacity", label: "Capacity", cssClass: ''},
    {attribute: "warranty", label: "Warranty", cssClass: ''},
    // {attribute: "type", label: "Product Type", cssClass: ''},
    {attribute: "unitGb", label: "Unit Capacity", cssClass: '', suffix: 'GB'},
    {attribute: "brand", label: "Brand", cssClass: ''},
];


export const DISK_INPUT_FIELDS: any = [
    {attribute: "market", label: "Market", type: 'text', group: 'detail', cssClass: ''},
    {attribute: "asin", label: "ASIN", type: 'text', group: 'detail', cssClass: ''},
    {attribute: "name", label: "Name", type: 'text', group: 'detail', cssClass: ''},
    {attribute: "formFactor", label: "Form Factor", type: 'text', group: 'detail', cssClass: ''},
    {attribute: "technology", label: "Technology", type: 'text', group: 'detail', cssClass: ''},
    {attribute: "condition", label: "Condition", type: 'text', group: 'detail', cssClass: ''},
    // {attribute: "url", label: "URL", type: 'text', group: 'detail', cssClass: ''},
    {attribute: "pricePerGb", label: "Price Per GB", type: 'text', group: 'detail', cssClass: ''},
    {attribute: "pricePerTb", label: "Price Per TB", type: 'text', group: 'detail', cssClass: ''},
    {attribute: "price", label: "Price", type: 'text', group: 'detail', cssClass: ''},
    {attribute: "capacity", label: "Capacity", type: 'text', group: 'detail', cssClass: ''},
    {attribute: "warranty", label: "Warranty", type: 'text', group: 'detail', cssClass: ''},
    {attribute: "type", label: "Product Type", type: 'text', group: 'detail', cssClass: ''},
    {attribute: "unitGb", label: "Unit Capacity", type: 'text', group: 'detail', cssClass: ''},
    {attribute: "brand", label: "Brand", type: 'text', group: 'detail', cssClass: ''},
];

export const DISK_FILTER_FIELDS: any = [
    {attribute: "condition", label: "Condition", type: 'select', options: [], group: 'detail', cssClass: ''},
    {attribute: "formFactor", label: "Form Factor", type: 'select', options: [], group: 'detail', cssClass: ''},
    {attribute: "technology", label: "Technology", type: 'select', options: [], group: 'detail', cssClass: ''},
];

export const AMAZON_MARKETS = [
    {
        key: 'US',
        id: 'us',
        label: 'Amazon US',
        url: 'https://www.amazon.com/dp/',
        href: '/markets/us',
        tag: 'disknovice-20',
    },
    {
        key: 'CA',
        id: 'ca',
        label: 'Amazon Canada',
        url: 'https://www.amazon.ca/dp/',
        href: '/markets/ca',
        tag: 'disknovice05-20',
    },
    {
        key: 'UK',
        id: 'uk',
        label: 'Amazon UK',
        url: 'https://www.amazon.co.uk/dp/',
        href: '/markets/uk',
        tag: 'disknovice-21',
    },
    {
        key: 'IN',
        id: 'in',
        label: 'Amazon India',
        url: 'https://www.amazon.in/dp/',
        href: '/markets/in',
        tag: 'disknovice03-21',
    },
    {
        key: 'FR',
        id: 'fr',
        label: 'Amazon France',
        url: 'https://www.amazon.fr/dp/',
        href: '/markets/fr',
        tag: 'disknovice01-21',
    },
    {
        key: 'DE',
        id: 'de',
        label: 'Amazon Germany',
        url: 'https://www.amazon.de/dp/',
        href: '/markets/de',
        tag: 'disknovice00-21',
    },
    {
        key: 'ES',
        id: 'es',
        label: 'Amazon Spain',
        url: 'https://www.amazon.es/dp/',
        href: '/markets/es',
        tag: 'disknovice05-21',
    },
];

export const AMAZON_MARKETS_MAP = _.keyBy(AMAZON_MARKETS, 'key');

export const PAGE_LIMIT_LIST = [
    {key: 0, label: 'All'},
    {key: 10, label: '10'},
    {key: 20, label: '20'},
    {key: 30, label: '30'},
    {key: 40, label: '40'},
    {key: 50, label: '50'},
    {key: 60, label: '60'},
    {key: 70, label: '70'},
    {key: 80, label: '80'},
    {key: 90, label: '90'},
    {key: 100, label: '100'},
];

export const PAGINATION_FIELD_LIST = [
    {
        attribute: 'limit',
        label: 'Limit',
        type: 'select',
        readOnly: false,
        group: 'pagination',
        options: PAGE_LIMIT_LIST,
        default: '10'
    },
];
