import React, {useEffect, useState} from 'react';
import './App.css';
import {useAuthState} from "react-firebase-hooks/auth";
import _ from "lodash";
import {auth} from "./modules/firebase/client-app";
import Layout from "./modules/layout/layout";
import AppContext from './modules/common/context/app-context';
import LoginDetail from "./modules/security/components/login-detail"
import {getSingleUser} from "./modules/security/services/user-data-service";
import ShowNoAccessMessage from "./modules/security/components/show-no-access-message";

const App = () => {
    const [profile, setProfile] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, loading, error] = useAuthState(auth);

    useEffect(() => {
        if (!loading) {
            processLoggedInUser();
        }
    }, [loading, user]);

    const processLoggedInUser = async () => {
        if (user) {
            setIsAuthenticated(true);
            const userInfo = await getSingleUser(user?.uid);
            const userDetail = userInfo?.data();
            const currentUser = _.cloneDeep(user);
            currentUser['isEnabled'] = userDetail?.isEnabled || false;

            setProfile(currentUser);
        } else {
            setIsAuthenticated(false);
        }
    }

    return (

        <AppContext.Provider
            value={
                {
                    user: user,
                    profile: profile,
                    isAuthenticated: !_.isEmpty(user),
                    isEnabled: profile?.isEnabled,
                }
            }>

            {!loading &&

                <>
                    {isAuthenticated && profile?.isEnabled &&
                        <Layout></Layout>
                    }

                    {!isAuthenticated &&
                        <LoginDetail/>
                    }

                    {isAuthenticated && profile && !profile?.isEnabled &&
                        <ShowNoAccessMessage/>
                    }
                </>

            }

        </AppContext.Provider>
    );
}

export default App;
