import React, {useState} from 'react'
import {CheckIcon, ClipboardDocumentIcon} from "@heroicons/react/24/solid";
import {CopyToClipboard} from 'react-copy-to-clipboard';

const CopyDataToClipboard = ({value, label = '', sizeClass = 'px-1 py-1'}) => {
    const [inProgress, setInProgress] = useState(false);

    const copyData = () => {
        setInProgress(true);

        setTimeout(async () => {
            setInProgress(false);
        }, 300);
    }

    return (
        <>
            <CopyToClipboard
                text={value}
                onCopy={copyData}>
                <button
                    className={`m-1 rounded flex items-center justify-center border text-gray-500 hover:text-white ${sizeClass}
                    ${inProgress ? "bg-green-100 hover:bg-green-500 hover:border-green-500" : "border-gray-600 bg-gray-100 hover:bg-purple-500 hover:border-purple-500"}`}>
                    {label &&
                        <div className={'mx-1'}>{label}</div>
                    }
                    {!inProgress &&
                        <div className={`mx-1`}>
                            <ClipboardDocumentIcon className="h-6 w-6"/>
                        </div>
                    }
                    {inProgress &&
                        <div className={`mx-1`}>
                            <CheckIcon className="h-6 w-6"/>
                        </div>
                    }
                </button>
            </CopyToClipboard>
        </>
    )
}

export default CopyDataToClipboard
