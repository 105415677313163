import React, {useEffect, useState} from 'react';
import _ from "lodash";
import {AMAZON_MARKETS_MAP, DISK_FILTER_FIELDS, PAGINATION_FIELD_LIST} from "../constants/disk.constant";
import {handleFieldValueChanges, prepareInputFields} from "../../common/utils/field-change-util";
import RenderFields from '../../common/ui/render-fields';

const emptyFilterCriteria: any = {};
const allFilterFields: any = [];

const DiskFilters = ({filterCriteria, setFilterCriteria, diskMarket}) => {
    // const [pagination, setPagination] = useState(defaultPagination);
    const [diskFilterFields, setDiskFilterFields] = useState(null);

    useEffect(() => {
        prepareFilters();
    }, [diskMarket]);

    const prepareFilters = () => {
        const currentDiskFilterFields = [];
        const allFilterFields = _.cloneDeep(DISK_FILTER_FIELDS);
        const filterData = diskMarket.filters;

        _.forEach(allFilterFields, filterField => {
            const fieldFilterData = filterData[filterField.attribute];
            if (fieldFilterData) {
                filterField.options = _.orderBy(fieldFilterData.options, 'key');
                currentDiskFilterFields.push(filterField);
            }
        });

        setDiskFilterFields(currentDiskFilterFields);
    }

    /*const handlePaginationChanges = (event) => {
        let existingPagination = {...pagination};
        existingPagination = handleFieldValueChanges(event, existingPagination);
        setPagination(existingPagination);

        handleSearchDesigns(false, null, existingPagination);
    }*/

    const handleFilterChanges = (event) => {
        const existingFilterCriteria = {...filterCriteria};
        handleFieldValueChanges(event, existingFilterCriteria, setFilterCriteria);
    }

    const clearFilterCriteria = () => {
        setFilterCriteria(emptyFilterCriteria);
    }

    return (
        <>
            <div className="flex flex-wrap justify-start w-full">
                <RenderFields
                    parentForm={filterCriteria}
                    handleDetailChanges={handleFilterChanges}
                    fieldList={diskFilterFields}
                    enableEdit={true}
                    fieldClassName={`w-1/(${diskFilterFields?.length})`}/>

                {/*<div className="flex flex-wrap justify-start">
                    <RenderFields
                        parentForm={pagination}
                        handleDetailChanges={handlePaginationChanges}
                        fieldList={_.groupBy(PAGINATION_FIELD_LIST, 'group')['pagination']}
                        enableEdit={true}
                        fieldClassName={'w-fit'}/>
                </div>*/}

                <div className="w-40">
                    <button
                        onClick={() => clearFilterCriteria()}
                        className="button-link mt-9">
                        Clear Filters
                    </button>
                </div>
            </div>
        </>
    )
}

export default DiskFilters
