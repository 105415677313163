import _ from "lodash";

export const handleFieldValueChanges = (event, parent, setParent = undefined) => {
    const existingParent = {...parent};
    const {type, name, value} = event.target;

    if (type === 'checkbox') {
        existingParent[name] = !existingParent[name];
    } else if (type === 'number') {
        existingParent[name] = _.parseInt(value);
    } else {
        existingParent[name] = value;
    }

    if (setParent) {
        setParent(existingParent);
    } else {
        return existingParent;
    }
}

export const prepareInputFields = (allFields, currentField, parent) => {
    const newFields = [];

    _.forEach(allFields, (field) => {
        if (field && !_.isEmpty(field.showWhen) && field.showWhen.attribute === currentField?.name) {
            if (field.showWhen.values.indexOf(currentField?.value) === -1) {
                parent[field.attribute] = '';
            } else {
                newFields.push(field);
            }
        } else {
            newFields.push(field);
        }
    });

    return newFields;
}
