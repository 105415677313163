import axios from "axios";
import {cloudFunctionConfig, getCurrentUserIdToken} from "../../firebase/client-app";

export {
    generateDiskContent
}

const baseUrl = cloudFunctionConfig.localBaseUrl || cloudFunctionConfig.baseUrl;
const DISK_CONTENT_API_URL = "/disk/content";

const generateDiskContent = async (diskInfo) => {
    let url = baseUrl + DISK_CONTENT_API_URL;
    return await makePostCall(url, {diskInfo});
}

const makeGetCall = async (url) => {
    const idToken = await getCurrentUserIdToken();

    const config = {
        method: 'get',
        url: url,
        headers: {
            Authorization: `Bearer ${idToken}`,
        }
    }

    try {
        const {data} = await axios.request(config);
        return data;
    } catch (error) {
        console.error('Error making GET call', error);
        return {error: error};
    }
}

const makePostCall = async (url, input) => {
    const idToken = await getCurrentUserIdToken();

    const config = {
        method: 'post',
        url: url,
        headers: {
            Authorization: `Bearer ${idToken}`,
        },
        data: input
    }

    try {
        const {data} = await axios.request(config);
        return data;
    } catch (error) {
        console.error('Error making POST call', error);
        return {error: error};
    }
}

