export const DATA_LIMIT = 20;

export const DISK_SYNC_FIELDS_LIST: any = [
    {
        attribute: "id",
        label: "Id",
        cssClass: 'w-48',
    },
    {
        attribute: "type",
        label: "Type",
        cssClass: 'w-36',
    },
    {
        attribute: "marketLabel",
        label: "Market",
        cssClass: 'w-48',
    },
    {
        attribute: "status",
        label: "Status",
        cssClass: 'w-24 text-center',
    },
    {
        attribute: "total",
        label: "Total",
        cssClass: 'w-24 text-center',
    },
    {
        attribute: "complete",
        label: "Completed",
        cssClass: 'w-24 text-center',
    },
    {
        attribute: "inComplete",
        label: "Incompleted",
        cssClass: 'w-24 text-center',
    },
    {
        attribute: "completedPercentage",
        label: "Completed %",
        cssClass: 'w-28 font-bold text-center',
    },
    {
        attribute: "createdAtText",
        label: "Created At",
        cssClass: 'w-48 text-center',
        // isDate: true
    },
    {
        attribute: "updatedAtText",
        label: "Updated At",
        cssClass: 'w-48 text-center',
        // isDate: true
    },
];


export const DISK_SYNC_INPUT_FIELDS: any = [
    {
        attribute: "id",
        label: "Id",
        type: 'text',
        group: 'detail',
        cssClass: ''
    },
    {
        attribute: "status",
        label: "Status",
        type: 'text',
        group: 'detail',
        cssClass: ''
    },
    {
        attribute: "total",
        label: "Total",
        type: 'text',
        group: 'detail',
        cssClass: ''
    },
    {
        attribute: "createdAt",
        label: "Created At",
        type: 'text',
        group: 'detail',
        cssClass: ''
    },
    {
        attribute: "updatedAtText",
        label: "Updated At",
        type: 'text',
        group: 'detail',
        cssClass: ''
    },
];
