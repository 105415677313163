import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    setDoc,
    where,
    orderBy,
    limit
} from "firebase/firestore";
import {fireStoreDB} from "../../firebase/client-app";
import {Promise} from "bluebird";
import _ from "lodash";
import {DATA_LIMIT} from "../constants/user.constant";

export {
    addUsers,
    addSingleUser,
    updateSingleUser,
    getSingleUser,
    searchUsers,
    searchUsersWithSearchCriteria,
    prepareUsersList
}

const collectionName = 'users';
const collectionReference = collection(fireStoreDB, collectionName);

const addUsers = (users) => {
    return Promise.map(users, async user => {
        await addSingleUser(user);
    });
}

const addSingleUser = (user) => {
    user.createdAt = new Date();
    user.updatedAt = user.createdAt;
    return addDoc(collectionReference, user);
}

const updateSingleUser = (user) => {
    const documentReference = doc(fireStoreDB, collectionName, user.id);
    user.updatedAt = new Date();
    return setDoc(documentReference, user);
}

const getSingleUser = (userId) => {
    const documentReference = doc(fireStoreDB, collectionName, userId);
    return getDoc(documentReference);
}

const searchUsers = async (searchText) => {
    let searchQuery = query(collectionReference);

    if (searchText) {
        searchQuery = query(collectionReference, where("textList", "array-contains", searchText));
    }

    searchQuery = query(searchQuery,
        orderBy('updatedAt', 'desc'));

    searchQuery = query(searchQuery, limit(DATA_LIMIT));

    const userResult = await getDocs(searchQuery);
    return prepareUsersList(userResult);
}

const searchUsersWithSearchCriteria = async (searchCriteriaList) => {
    let searchQuery = query(collectionReference);

    // console.log('searchUsersWithSearchCriteria', {searchCriteriaList});

    _.forEach(searchCriteriaList, searchCriteria => {
        searchQuery = query(searchQuery,
            where(searchCriteria.field, searchCriteria.condition, searchCriteria.value));
    });

    if (searchCriteriaList.length === 0) {
        searchQuery = query(searchQuery, orderBy('updatedAt', 'desc'));
    }

    searchQuery = query(searchQuery, limit(DATA_LIMIT));

    const userResult = await getDocs(searchQuery);
    return prepareUsersList(userResult);
}

const prepareUsersList = (userResult) => {
    const usersList = [];

    _.forEach(userResult.docs, userDoc => {
        usersList.push({
            ...userDoc.data(),
            id: userDoc.id
        });
    });

    return usersList;
}
