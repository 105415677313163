import React from 'react';

const TimelineDuration = ({timeline}) => {
    return (
        <>
            {timeline?.duration &&
                <div>
                    {timeline?.duration.hours > 0 &&
                        <>
                        <span
                            className={'ml-1'}>{timeline?.duration.hours}</span> {timeline?.duration.hours > 1 ? 'Hours' : 'Hour'}
                        </>
                    }
                    <span
                        className={'ml-1'}>{timeline?.duration.minutes}</span> {timeline?.duration.minutes > 1 ? 'Minutes' : 'Minute'}
                    <span
                        className={'ml-1'}>{timeline?.duration.seconds}</span> {timeline?.duration.seconds > 1 ? 'Seconds' : 'Second'}
                </div>
            }
        </>
    );
};

export default TimelineDuration;
