import React, {useEffect, useState} from 'react';
import _ from "lodash";
import {AMAZON_MARKETS_MAP, DEFAULT_MARKET_KEY, DISK_FIELDS_LIST, DISK_MARKET_SUFFIX} from "../constants/disk.constant";
import {searchDisksWithSearchCriteria} from "../services/disk-data.service";
import ShowProgressSpinner from '../../common/ui/show-progress-spinner';
import ShowEmptyListMessage from '../../common/ui/show-empty-list-message';
import {syncDiskPrices} from "../../common/services/cloud-function-service";
import DiskFilters from './disk-filters';
import {getSingleDiskMarket} from "../services/disk-market-data.service";
import ThumbnailImage from './thumbnail-image';
import {getCurrentDiskSyncByType} from "../../disk-sync/utils/disk-sync-util";
import {updateSingleDiskSync} from "../../disk-sync/services/disk-sync-data.service";
import {getSingleDiskDetail} from "../services/disk-detail-data.service";
import {prepareDiskInfo} from "../utils/disk-util";
import {generateDiskContent} from "../services/disk-content-api.service";

const defaultFilterCriteria: any = {};

const DisksList = ({marketKey = DEFAULT_MARKET_KEY}) => {
    const [disksList, setDisksList] = useState(null);
    const [filteredDisksList, setFilteredDisksList] = useState(null);
    const [filterCriteria, setFilterCriteria] = useState(defaultFilterCriteria);
    const [dataFetchInProgress, setDataFetchInProgress] = useState(true);
    const [selectedMarket, setSelectedMarket] = useState(null);
    const [diskMarket, setDiskMarket] = useState(null);
    const [diskSync, setDiskSync] = useState(null);

    useEffect(() => {
        setSelectedMarket(AMAZON_MARKETS_MAP[marketKey || DEFAULT_MARKET_KEY]);
        setFilterCriteria(defaultFilterCriteria);

        getDiskMarket();
        getDiskList();
    }, [marketKey]);

    useEffect(() => {
        if (disksList) {
            // applyFilters();
            getDiskList();
        }
    }, [filterCriteria]);

    const getDiskList = async () => {
        setDataFetchInProgress(true);

        const searchCriteriaList = [
            {
                field: "market",
                condition: '==',
                value: marketKey ? marketKey : DEFAULT_MARKET_KEY
            },
            {
                field: "disabled",
                condition: '==',
                value: false
            }];

        _.forIn(filterCriteria, (value, attribute) => {
            if ((!_.isEmpty(value) || value === true) && value !== 'EMPTY') {
                searchCriteriaList.push({field: attribute, condition: '==', value: value});
            }
        });

        const diskListResult = await searchDisksWithSearchCriteria(searchCriteriaList);
        // const diskListResult = await searchDisksWithSearchCriteria(searchCriteriaList, -1);

        setDisksList(diskListResult);
        applyFilters(diskListResult);
        setDataFetchInProgress(false);
    }

    const applyFilters = (diskListResult?) => {
        setDataFetchInProgress(true);
        let filteredList = diskListResult || [...disksList];
        let existingFilterCriteria = {...filterCriteria};
        const filters = {};

        _.forEach(existingFilterCriteria, (filterValue, filterKey) => {
            if (filterValue) {
                if (filterValue === 'Yes') {
                    filters[filterKey] = true;
                } else if (filterValue === 'No') {
                    filters[filterKey] = false;
                } else {
                    filters[filterKey] = filterValue;
                }
            }
        });

        filteredList = _.filter(filteredList, filters);
        setFilteredDisksList(filteredList);
        setDataFetchInProgress(false);
    }

    const getDiskMarket = async () => {
        const currentMarket = marketKey ? marketKey : DEFAULT_MARKET_KEY;
        const marketId = DISK_MARKET_SUFFIX + '-' + _.toLower(currentMarket);

        const diskMarketResult = await getSingleDiskMarket(marketId);

        setDiskMarket(diskMarketResult);
        await getCurrentDiskSync(diskMarketResult);
    }

    const startDiskPricesSyncProcess = async () => {
        setDataFetchInProgress(true);
        await syncDiskPrices();

        applyFilters();
        setDataFetchInProgress(false);
    }

    const getCurrentDiskSync = async (currentDiskMarket) => {
        const diskSyncResult = await getCurrentDiskSyncByType('DISK-CONTENT', currentDiskMarket.market);
        diskSyncResult.total = disksList?.total || 0;
        setDiskSync(diskSyncResult);
    }

    const addToDiskSyncForContent = async () => {
        setDataFetchInProgress(true);

        const currentDiskSync = {...diskSync};
        const currentDisks = {...disksList};
        const asins = _.map(currentDisks, 'asin');
        const existingAsins = _.uniq(_.compact(_.concat((currentDiskSync.asins || []), asins)));

        currentDiskSync.total = existingAsins.length;
        currentDiskSync.asins = existingAsins;

        console.log('currentDiskSync', currentDiskSync);

        await updateSingleDiskSync(currentDiskSync);
        setDiskSync(currentDiskSync);
        setDataFetchInProgress(false);
    }

    const getDiskDetail = async (disk) => {
        const currentMarket = {...diskMarket};
        const diskDetailId = currentMarket.market + '|' + disk?.asin;

        const diskDetail = await getSingleDiskDetail(diskDetailId);
        const diskInfo = await prepareDiskInfo(diskDetail);
        const diskContent = await generateDiskContent(diskInfo);

        console.log('diskDetail & diskInfo:', {diskDetail, diskInfo, diskContent});
    }

    return (
        <>

            <div className="flex justify-between">

                <div className="flex justify-start items-center -ml-2 mb-2 w-full">
                    <div className={'text-xl ml-2'}>
                        <span className={'mr-1'}>Disks</span>

                        {selectedMarket &&
                            <>
                                <span className={'mx-1'}>|</span>
                                <span className={'mx-1'}>{selectedMarket?.label}</span>
                            </>
                        }

                        {disksList?.length > 0 &&
                            <>
                                <span className={'mx-1'}>|</span>
                                <span
                                    className={'mx-1 text-gray-500'}>({filteredDisksList?.length} / {diskMarket?.enabled})</span>
                            </>
                        }
                    </div>

                    {diskMarket &&
                        <div className={'ml-4 flex justify-start items-center'}>
                            <DiskFilters
                                diskMarket={diskMarket}
                                filterCriteria={filterCriteria}
                                setFilterCriteria={setFilterCriteria}/>

                            <div className="ml-4">
                                <ShowProgressSpinner dataFetchInProgress={dataFetchInProgress}/>
                            </div>
                        </div>
                    }
                </div>

                <div className="text-center w-1/6 flex items-center justify-end">
                    <div>
                        <button
                            className="button-green px-4 py-2 flex items-center justify-center"
                            onClick={addToDiskSyncForContent}>
                            <span>Add to Disk Sync for Content</span>
                        </button>
                    </div>
                </div>

            </div>

            <div className="flex justify-start">

                <div
                    className="w-full bg-white mt-4">

                    <ShowEmptyListMessage
                        dataList={filteredDisksList}
                        dataFetchInProgress={dataFetchInProgress}
                        label={'Disks'}/>

                    {filteredDisksList?.length > 0 &&
                        <table className="w-full">
                            <thead className="">
                            <tr>
                                <th className="p-2 border border-gray-400 bg-gray-400 text-white w-12">S.No</th>
                                {DISK_FIELDS_LIST?.map((diskField, rowIndex) => (
                                    <th key={`th-${rowIndex}`}
                                        className={`p-2 border border-gray-400 bg-gray-400 text-white text-left pl-2 ${diskField?.cssClass}`}>{diskField?.label}</th>
                                ))}
                                <th className="p-2 border border-gray-400 bg-gray-400 text-white">
                                    Amazon Links
                                </th>
                            </tr>
                            </thead>
                            <tbody className="">
                            {filteredDisksList?.map((disk, rowIndex) => (
                                <tr key={`row-${rowIndex}`} className="border border-gray-400">
                                    <td className="p-2 border border-gray-400 m-auto">
                                        <div className="flex justify-center w-12">
                                            {rowIndex + 1}
                                        </div>
                                    </td>

                                    {DISK_FIELDS_LIST?.map((diskField, columnIndex) => (
                                        <React.Fragment key={`column-${rowIndex}-${columnIndex}`}>
                                            <td className={`p-2 border border-gray-400 m-auto ${diskField?.cssClass}`}>
                                                {diskField?.optionsMap && !diskField?.isImage &&
                                                    <>{diskField?.optionsMap[disk[diskField?.attribute]]?.name}</>
                                                }

                                                {!diskField?.optionsMap && !diskField?.isImage &&
                                                    <>
                                                        {disk[diskField?.attribute]}

                                                        {diskField?.suffix &&
                                                            <span className={'pl-1'}>{diskField?.suffix}</span>
                                                        }

                                                        {diskField?.secondaryAttribute &&
                                                            <div className={'text-gray-500 text-sm'}>
                                                                {disk[diskField?.secondaryAttribute]}
                                                            </div>
                                                        }
                                                    </>
                                                }

                                                {diskField?.isImage &&
                                                    <>
                                                        {disk[diskField?.attribute] &&
                                                            <ThumbnailImage
                                                                imageUrl={disk[diskField?.attribute]}
                                                                altText={disk[diskField?.secondaryAttribute]}/>
                                                        }
                                                    </>
                                                }
                                            </td>
                                        </React.Fragment>
                                    ))}

                                    <td className="p-2 border border-gray-400 m-auto w-64">
                                        <div className="flex justify-center items-center">
                                            <a href={`${disk?.url}`}
                                               target={'_blank'}
                                               className={'text-blue-500 mx-1'}>Amazon {disk.market}</a>

                                            <div className="mx-1">
                                                <button
                                                    className="button-green-outline px-3 py-1 flex items-center justify-center"
                                                    onClick={() => {
                                                        getDiskDetail(disk);
                                                    }}>
                                                    <span>Get Details</span>
                                                </button>
                                            </div>
                                        </div>


                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    }
                </div>

            </div>

        </>
    )
}

export default DisksList
