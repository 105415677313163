import React, {useEffect, useState} from 'react';
import _ from "lodash";
import {searchDiskDetailSyncsWithSearchCriteria} from "../services/disk-detail-sync-data.service";
import ShowProgressSpinner from '../../common/ui/show-progress-spinner';
import ShowEmptyListMessage from '../../common/ui/show-empty-list-message';
import TimelineDuration from "./timeline-duration";

const defaultFilterCriteria: any = {};

const DiskDetailSyncsListTable = () => {
    const [diskDetailSyncsList, setDiskDetailSyncsList] = useState(null);
    const [filterCriteria, setFilterCriteria] = useState(defaultFilterCriteria);
    const [dataFetchInProgress, setDataFetchInProgress] = useState(true);

    const getDiskDetailSyncList = async () => {
        setDataFetchInProgress(true);

        const searchCriteriaList = [];

        _.forIn(filterCriteria, (value, attribute) => {
            if ((!_.isEmpty(value) || value === true) && value !== 'EMPTY') {
                searchCriteriaList.push({field: attribute, condition: '==', value: value});
            }
        });

        let diskDetailSyncListResult = await searchDiskDetailSyncsWithSearchCriteria(searchCriteriaList);
        diskDetailSyncListResult = _.orderBy(diskDetailSyncListResult, ['market'], ['asc']);

        setDiskDetailSyncsList(_.orderBy(diskDetailSyncListResult, ['updatedAt'], ['desc']));
        setDataFetchInProgress(false);
    }

    useEffect(() => {
        getDiskDetailSyncList();
    }, [filterCriteria]);

    const refreshDiskDetailSyncList = async () => {
        await getDiskDetailSyncList();
    }

    return (
        <>

            <div className="flex justify-between">

                <div className="flex justify-start items-center mt-2 -ml-2 mb-2 w-full">
                    <div className={'text-xl ml-2'}>
                        Disk Detail Sync
                    </div>

                    <div className="ml-4">
                        <ShowProgressSpinner dataFetchInProgress={dataFetchInProgress}/>
                    </div>
                </div>

            </div>

            <div className="flex justify-start">

                <div
                    className="w-full bg-white mt-4">

                    <ShowEmptyListMessage
                        dataList={diskDetailSyncsList}
                        dataFetchInProgress={dataFetchInProgress}
                        label={'Disk Detail Syncs'}/>

                    {diskDetailSyncsList?.length > 0 &&
                        <table className="w-full">
                            <thead className="">
                            <tr>
                                <th className={"row-header-center w-20"}>S.No</th>
                                <th className={"row-header-center"}>Market</th>
                                <th className={"row-header-center"}>Status</th>
                                <th className={"row-header-center"}>Total</th>
                                <th className={"row-header-center"}>Completed</th>
                                <th className={"row-header-center"}>Failed</th>
                                <th className={"row-header-center"}>Timeline</th>
                                <th className={"row-header-center"}>Completed At</th>
                            </tr>
                            </thead>
                            <tbody className="">
                            {diskDetailSyncsList?.map((diskDetailSync, rowIndex) => (
                                <tr key={`row-${rowIndex}`} className="border border-gray-400">
                                    <td className="row-data-center">
                                        {rowIndex + 1}
                                    </td>
                                    <td className={"row-data-center"}>
                                        {diskDetailSync.market}
                                    </td>
                                    <td className={"row-data-center"}>
                                        {diskDetailSync.status}
                                    </td>
                                    <td className={"row-data-center"}>
                                        {diskDetailSync.total}
                                    </td>
                                    <td className={"row-data-center"}>
                                        {diskDetailSync.completed}
                                    </td>
                                    <td className={"row-data-center"}>
                                        {diskDetailSync.failed}
                                    </td>
                                    <td className={"row-data-center"}>
                                        <TimelineDuration timeline={diskDetailSync?.timeline}/>
                                    </td>
                                    <td className={"row-data-center"}>
                                        {diskDetailSync?.updatedAtText}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    }
                </div>

            </div>

        </>
    )
}

export default DiskDetailSyncsListTable
