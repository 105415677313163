import React from 'react'

const RenderFields = ({
                          fieldList,
                          parentForm = {},
                          handleDetailChanges,
                          enableEdit,
                          enableEditOnHover = false,
                          fieldClassName = 'w-full',
                          noFieldLabel = false,
                          attributeName = 'attribute',
                      }) => {

    // console.log('RenderFields', {fieldList, parentForm});

    return (
        <>
            {fieldList?.map((field, index) => (

                <React.Fragment key={index}>
                    {field?.type === 'text' &&
                        (!field?.showWhen || (field?.showWhen?.values?.indexOf(parentForm[field?.showWhen.attribute]) !== -1)) &&
                        <div
                            className={`field-container ${field?.cssClass ? field?.cssClass : fieldClassName}`}>
                            {!noFieldLabel &&
                                <div className="field-label w-full">
                                    {field?.label || field?.displayLabel}
                                    <span
                                        className={'text-gray-600 font-normal text-sm self-center ml-2'}>{field?.subLabel}</span>
                                </div>
                            }
                            <input
                                type="text"
                                className="input-field w-full"
                                disabled={field?.readOnly || !enableEdit}
                                id={field[attributeName]}
                                name={field[attributeName]}
                                placeholder={field?.placeHolder || field?.label || field?.displayLabel}
                                value={parentForm[field[attributeName]] || field?.value || ''}
                                onChange={handleDetailChanges}>
                            </input>
                        </div>
                    }

                    {field?.type === 'date' &&
                        <div className={`field-container ${field?.cssClass ? field?.cssClass : fieldClassName}`}>
                            {!noFieldLabel &&
                                <div className="field-label w-full">
                                    {field?.label || field?.displayLabel}
                                </div>
                            }
                            <input
                                type="date"
                                className="input-field-max w-full"
                                disabled={field?.readOnly || !enableEdit}
                                id={field[attributeName]}
                                name={field[attributeName]}
                                placeholder={field?.placeHolder || field?.label || field?.displayLabel}
                                value={parentForm[field[attributeName]] || field?.value || ''}
                                onChange={handleDetailChanges}>
                            </input>
                        </div>
                    }

                    {field?.type === 'number' &&
                        <div
                            className={`field-container ${field?.cssClass ? field?.cssClass : fieldClassName}`}>
                            {!noFieldLabel &&
                                <div className="field-label w-full">
                                    {field?.label || field?.displayLabel}
                                </div>
                            }
                            <input
                                type="number"
                                className="input-field-max w-full"
                                disabled={field?.readOnly || !enableEdit}
                                id={field[attributeName]}
                                name={field[attributeName]}
                                placeholder={field?.placeHolder || field?.label || field?.displayLabel}
                                value={parentForm[field[attributeName]] || field?.value || ''}
                                onChange={handleDetailChanges}>
                            </input>
                        </div>
                    }

                    {field?.type === 'checkbox' &&
                        <div
                            className={`items-center justify-start flex field-container ${field?.cssClass ? field?.cssClass : fieldClassName}`}>
                            <input
                                type="checkbox"
                                className="h-6 w-6 mr-1"
                                disabled={field?.readOnly || !enableEdit}
                                id={field[attributeName]}
                                name={field[attributeName]}
                                checked={parentForm[field[attributeName]] || field?.value || false}
                                onChange={handleDetailChanges}>
                            </input>
                            <div className=''>
                                <label className='field-label self-center mt-1 ml-1'
                                       htmlFor={field[attributeName]}>{field?.label || field?.displayLabel}</label>
                            </div>
                        </div>
                    }

                    {field?.type === 'text-area' &&
                        <div className={`field-container ${field?.cssClass ? field?.cssClass : fieldClassName}`}>
                            {!noFieldLabel &&
                                <div className="field-label w-full">
                                    {field?.label || field?.displayLabel}
                                </div>
                            }
                            <textarea
                                rows={field?.rows || 1}
                                disabled={field?.readOnly || !enableEdit}
                                id={field[attributeName]}
                                name={field[attributeName]}
                                placeholder={field?.placeHolder || field?.label || field?.displayLabel}
                                value={parentForm[field[attributeName]] || field?.value || ''}
                                onChange={handleDetailChanges}
                                className={`input-field-max w-full`}/>
                        </div>
                    }

                    {field?.type === 'select' &&
                        <div
                            className={`field-container ${field?.cssClass ? field?.cssClass : fieldClassName}`}>
                            {!noFieldLabel &&
                                <div className="field-label w-full">
                                    {field?.label || field?.displayLabel}
                                </div>
                            }
                            <select
                                disabled={field?.readOnly || !enableEdit}
                                className={`input-field-max w-full pt-2 pb-3`}
                                id={field[attributeName]}
                                name={field[attributeName]}
                                value={parentForm[field[attributeName]] || field?.value || field?.default}
                                onChange={handleDetailChanges}>
                                <option value="">Choose</option>
                                {field?.options?.map((option, index) => (
                                    <option key={index}
                                            value={option.key}>{option.label || option?.displayLabel || option.name}</option>
                                ))}
                            </select>
                        </div>
                    }
                </React.Fragment>

            ))}
        </>
    )
}

export default RenderFields
