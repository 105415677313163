import React from 'react';
import DiskList from "./disk-list";
import {useParams} from "react-router-dom";
import _ from "lodash";

const DiskDashboard = () => {
    const {marketKey} = useParams();

    return (
        <>

            <div className="page-container">

                <div className="items-center justify-start p-6 m-auto bg-white rounded-lg my-8 w-full mx-6">

                    <DiskList marketKey={_.toUpper(marketKey)}/>

                </div>

            </div>

        </>
    )
}

export default DiskDashboard
