import Header from "./header";
import Footer from "./footer";
import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import LoginDetail from "../security/components/login-detail";
import ShowNoAccessMessage from "../security/components/show-no-access-message";
import DiskDashboard from "../disk/components/disk-dashboard";
import DiskMarketDashboard from "../disk-market/components/disk-market-dashboard";
import DiskSyncDashboard from "../disk-sync/components/disk-sync-dashboard";
import DiskDetailSyncDashboard from "../disk-detail-sync/components/disk-detail-sync-dashboard";

const Layout = () => {

    return (
        <>
            <Header/>

            <Router>
                <Routes>
                    {/*<Route path='/' element={<Home/>}></Route>*/}
                    {/*<Route path='/' element={<DiskDashboard/>}></Route>*/}
                    <Route path='/' element={<DiskMarketDashboard/>}></Route>
                    <Route path='/disk-syncs' element={<DiskSyncDashboard/>}></Route>
                    <Route path='/disk-detail-syncs' element={<DiskDetailSyncDashboard/>}></Route>
                    <Route path='/markets' element={<DiskMarketDashboard/>}></Route>
                    <Route path='/markets/:marketKey' element={<DiskDashboard/>}></Route>
                    <Route path='/login' element={<LoginDetail/>}></Route>
                    <Route path='/no-access' element={<ShowNoAccessMessage/>}></Route>
                </Routes>
            </Router>

            <Footer/>
        </>
    );
}


export default Layout;
