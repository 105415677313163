import {diskNoviceServiceConfig} from "../../firebase/client-app";
import axios from "axios";
import _ from "lodash";

export {
    syncDiskDetails,
    syncAllMarketDiskDetails,
}

const syncDiskDetails = async (diskMarketId) => {
    let url = diskNoviceServiceConfig.baseUrl + diskNoviceServiceConfig.marketScrapeUrl;
    url = _.replace(url, "{diskMarketId}", _.toLower(diskMarketId));

    return await makeGetCall(url);
}

const syncAllMarketDiskDetails = async () => {
    let url = diskNoviceServiceConfig.baseUrl + diskNoviceServiceConfig.allMarketScrapeUrl;

    return await makeGetCall(url);
}

const makeGetCall = async (url, headers?) => {
    const {data} = await axios.get(url, {headers});
    return data;
}

const makePostCall = async (url, inputData, headers?) => {
    const options = {
        method: 'POST',
        body: JSON.stringify(inputData)
    }

    const response = await fetch(url, options);
    return response.json();
}
