import React from "react";

const AppContext = React.createContext({
    user: undefined,
    profile: undefined,
    isAuthenticated: false,
    isEnabled: false,
});

export default AppContext;
