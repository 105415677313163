import React from 'react';
import ShowProgressSpinner from './show-progress-spinner';


const ShowEmptyListMessage = ({dataList, dataFetchInProgress = false, label, showBackground = false}) => {
    return (
        <>
            {dataList?.length === 0 &&
                <div
                    className={`w-full h-96 flex items-center justify-center ${showBackground ? 'bg-gray-100 rounded-lg' : ''}`}>
                    <div className="mr-2">
                        <ShowProgressSpinner dataFetchInProgress={dataFetchInProgress}/>
                    </div>

                    <div className="text-xl text-gray-600">
                        {!dataFetchInProgress &&
                            <div>No {label} Available</div>
                        }
                        {dataFetchInProgress &&
                            <div>Fetching {label}... </div>
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default ShowEmptyListMessage
