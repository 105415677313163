import React from 'react';
import DiskSyncsList from "./disk-sync-list";

const DiskSyncDashboard = () => {
    return (
        <div className="page-container">

            <div className="items-center justify-start p-6 m-auto bg-white rounded-lg my-6 w-full mx-6">

                <DiskSyncsList/>

            </div>

        </div>
    );
};

export default DiskSyncDashboard;
