import React, {useEffect, useState} from 'react';
import _ from "lodash";
import {DISK_SYNC_FIELDS_LIST} from "../constants/disk-sync.constant";
import {searchDiskSyncsWithSearchCriteria, updateSingleDiskSync} from "../services/disk-sync-data.service";
import ShowProgressSpinner from '../../common/ui/show-progress-spinner';
import ShowEmptyListMessage from '../../common/ui/show-empty-list-message';
import CopyDataToClipboard from '../../common/ui/copy-data-to-clipboard';
import {AMAZON_MARKETS_MAP} from "../../disk/constants/disk.constant";


const defaultFilterCriteria: any = {};

const DiskSyncsList = () => {
    const [diskSyncsList, setDiskSyncsList] = useState(null);
    const [filterCriteria, setFilterCriteria] = useState(defaultFilterCriteria);
    const [dataFetchInProgress, setDataFetchInProgress] = useState(true);

    const getDiskSyncList = async () => {
        setDataFetchInProgress(true);

        const searchCriteriaList = [];

        _.forIn(filterCriteria, (value, attribute) => {
            if ((!_.isEmpty(value) || value === true) && value !== 'EMPTY') {
                searchCriteriaList.push({
                    field: attribute,
                    condition: '==',
                    value: value
                });
            }
        });

        console.log('searchCriteriaList', searchCriteriaList);

        const diskSyncListResult = await searchDiskSyncsWithSearchCriteria(searchCriteriaList);
        _.forEach(diskSyncListResult, (diskSync, index) => {
            diskSync.completedPercentage = Math.round((diskSync.complete / diskSync.total) * 100) + ' %';
            diskSync.marketLabel = AMAZON_MARKETS_MAP[diskSync.market].label;
        });

        // console.log('diskSyncListResult', diskSyncListResult);

        setDiskSyncsList(diskSyncListResult);
        setDataFetchInProgress(false);
    }

    useEffect(() => {
        getDiskSyncList();
    }, [filterCriteria]);

    const refreshDiskSyncList = async () => {
        await getDiskSyncList();
    }

    const startDiskSyncProcess = async (diskSync) => {
        setDataFetchInProgress(true);

        diskSync.status = 'STARTED';
        await updateSingleDiskSync(diskSync);

        await refreshDiskSyncList();
        setDataFetchInProgress(false);
    }


    return (
        <>

            <div className="flex justify-between">

                <div className="flex justify-start items-center -ml-2 mb-2 w-full">
                    <div className={'text-xl ml-2'}>
                        Disk Sync
                    </div>

                    <div className="ml-4">
                        <ShowProgressSpinner dataFetchInProgress={dataFetchInProgress}/>
                    </div>
                </div>

                <div className="text-center w-1/6 flex items-center justify-end">
                    <div className="">
                        <button
                            className="button-green-outline px-4 py-2 flex items-center justify-center"
                            onClick={refreshDiskSyncList}>
                            <span>Refresh</span>
                            <span className="ml-1">
                                <ShowProgressSpinner
                                    sizeClass={'w-5 h-5'}
                                    dataFetchInProgress={dataFetchInProgress}/>
                            </span>
                        </button>
                    </div>
                </div>

            </div>

            <div className="flex justify-start">

                <div
                    className="w-full bg-white mt-4">

                    <ShowEmptyListMessage
                        dataList={diskSyncsList}
                        dataFetchInProgress={dataFetchInProgress}
                        label={'Disk Syncs'}/>

                    {diskSyncsList?.length > 0 &&
                        <table className="w-full">
                            <thead className="">
                            <tr>
                                <th className="p-2 border border-gray-400 bg-gray-400 text-white w-12">S.No</th>
                                {DISK_SYNC_FIELDS_LIST?.map((diskSyncField, rowIndex) => (
                                    <th key={`th-${rowIndex}`}
                                        className={`p-2 border border-gray-400 bg-gray-400 text-white text-left pl-2 ${diskSyncField?.cssClass}`}>{diskSyncField?.label}</th>
                                ))}
                                <th className="p-2 border border-gray-400 bg-gray-400 text-white">
                                    Actions
                                </th>
                            </tr>
                            </thead>
                            <tbody className="">
                            {diskSyncsList?.map((diskSync, rowIndex) => (
                                <tr key={`row-${rowIndex}`} className="border border-gray-400">
                                    <td className="p-2 border border-gray-400 m-auto">
                                        <div className="flex justify-center w-12">
                                            {rowIndex + 1}
                                        </div>
                                    </td>

                                    {DISK_SYNC_FIELDS_LIST?.map((diskSyncField, columnIndex) => (
                                        <React.Fragment key={`column-${rowIndex}-${columnIndex}`}>
                                            <td className={`p-2 border border-gray-400 m-auto ${diskSyncField?.cssClass}`}>
                                                {diskSyncField?.optionsMap &&
                                                    <>{diskSyncField?.optionsMap[diskSync[diskSyncField?.attribute]]?.name}</>
                                                }

                                                {!diskSyncField?.optionsMap &&
                                                    <>
                                                        {!diskSyncField?.isDate &&
                                                            <>
                                                                {diskSync[diskSyncField?.attribute]}
                                                            </>
                                                        }

                                                        {diskSyncField?.secondaryAttribute &&
                                                            <div className={'text-gray-500 text-sm'}>
                                                                {diskSync[diskSyncField?.secondaryAttribute]}
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </td>
                                        </React.Fragment>
                                    ))}

                                    <td className="p-2 border border-gray-400 m-auto w-64">
                                        <div className="flex justify-center items-center">
                                            {((diskSync?.status === 'NEW' && diskSync?.total > 0) || diskSync?.inComplete > 0) &&
                                                <div className="">
                                                    <button
                                                        className="button-green px-4 py-1"
                                                        onClick={() => {
                                                            startDiskSyncProcess(diskSync)
                                                        }}>
                                                        <span>Start Disk Sync Process</span>
                                                    </button>
                                                </div>
                                            }

                                            <div className="flex justify-around">
                                                <CopyDataToClipboard value={JSON.stringify(diskSync, null, 2)}/>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    }
                </div>

            </div>

        </>
    )
}

export default DiskSyncsList
