import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    setDoc,
    where,
    orderBy,
    limit
} from "firebase/firestore";
import {fireStoreDB} from "../../firebase/client-app";
import {Promise} from "bluebird";
import _ from "lodash";
import {DATA_LIMIT} from "../constants/disk.constant";
import {prepareDocumentList, prepareSingleDocument} from "../../firebase/firebase-doc-util";

export {
    addDiskDetails,
    addSingleDiskDetail,
    updateSingleDiskDetail,
    getSingleDiskDetail,
    getAllDiskDetails,
    searchDiskDetails,
    searchDiskDetailsWithSearchCriteria,
}

const collectionName = 'disk-details';
const collectionReference = collection(fireStoreDB, collectionName);

const addDiskDetails = (diskDetails) => {
    return Promise.map(diskDetails, async diskDetail => {
        await addSingleDiskDetail(diskDetail);
    });
}

const addSingleDiskDetail = (diskDetail) => {
    diskDetail.createdAt = new Date();
    diskDetail.updatedAt = diskDetail.createdAt;
    return addDoc(collectionReference, diskDetail);
}

const updateSingleDiskDetail = (diskDetail) => {
    const documentReference = doc(fireStoreDB, collectionName, diskDetail.id);
    diskDetail.updatedAt = new Date();
    return setDoc(documentReference, diskDetail, {merge: true});
}

const getSingleDiskDetail = async (diskDetailId) => {
    const documentReference = doc(fireStoreDB, collectionName, diskDetailId);
    const diskDetailDoc = await getDoc(documentReference);
    return prepareSingleDocument(diskDetailDoc);
}

const getAllDiskDetails = async () => {
    let searchQuery = query(collectionReference);
    const diskDetailsResult = await getDocs(searchQuery);
    return prepareDocumentList(diskDetailsResult);
}

const searchDiskDetails = async (searchText, perPage = DATA_LIMIT) => {
    let searchQuery = query(collectionReference);

    if (searchText) {
        searchQuery = query(collectionReference, where("textList", "array-contains", searchText));
    }

    searchQuery = query(searchQuery,
        orderBy('updatedAt', 'desc'));

    searchQuery = query(searchQuery, limit(perPage));

    const diskDetailResult = await getDocs(searchQuery);
    return prepareDocumentList(diskDetailResult);
}

const searchDiskDetailsWithSearchCriteria = async (searchCriteriaList, perPage = DATA_LIMIT) => {
    let searchQuery = query(collectionReference);

    // console.log('searchDiskDetailsWithSearchCriteria', {searchCriteriaList});

    _.forEach(searchCriteriaList, searchCriteria => {
        searchQuery = query(searchQuery,
            where(searchCriteria.field, searchCriteria.condition, searchCriteria.value));
    });

    /*if (searchCriteriaList.length === 0) {
        searchQuery = query(searchQuery, orderBy('updatedAt', 'desc'));
    }*/

    searchQuery = query(searchQuery, limit(perPage));

    const diskDetailResult = await getDocs(searchQuery);
    return prepareDocumentList(diskDetailResult);
}
