import React from 'react'

const Footer = () => {
    return (
        <>

            <div className="relative bottom-0 left-0 bg-brand-blue w-full">
            </div>

        </>
    )
}

export default Footer
